<template>
    <div class="aboutUs">
        <h1>加入我们</h1>
        <p class="brief">
          杭州河象网络科技有限公司，是一个崇尚创新、充满理想、蓬勃发展的互联网在线教育平台，核心团队来自阿里巴巴、腾讯、网易等，公司带领着一支平均年龄不到30岁的高学历高颜值的年轻精英团队怀揣着对少儿教育的热爱共同开启了河小象的高速发展。
我们年轻，但不浮躁；我们激情，但不激进；我们优秀，但不自满，我们有着明确的规划方向和清晰的战略目标，致力于打造全国最具潜力的线上教育平台。
在这里，有轻松活跃的团队氛围；在这里，有快速发展的成长空间；在这里，有诱人丰厚的薪酬收入，有不断完善的福利体系，更有施展能力的舞台！
如果你热爱孩子，热爱教育，加入我们！如果你热爱拼搏，想要奋斗，加入我们！如果你期待成长，热血激情，加入我们！让我们一起成为并肩奋斗的伙伴，面向朝阳，挥洒汗水，开启新的篇章！
        </p>
        <h2>人事部联系方式：</h2>
        <p class="msg">
          <ul>
                <li><span>邮箱：</span>hr@hexiaoxiang.com（仅限投递简历使用）</li>
                <li><span>电话：</span>13372540612 河象HR  </li>
          </ul>
        </p>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.aboutUs{
    text-align: left;
    padding: .853rem .493rem;
    
    h1{
        font-size: .533rem;
        color:#444144;
        font-weight: bold;
    }
    .brief{
        font-size: .347rem;
        color: #444144;
        line-height: .533rem;
        text-indent:2em;
        margin: .36rem 0 .4rem;
    }
    h2{
        color: #FF7041;
        font-size: .427rem;
        font-weight: bold;
    }
    .msg{
        font-size: .347rem;
        color: #444144;
        line-height: .533rem;
        margin: .227rem 0 .507rem;
    }
    .msg li span{
        font-weight: bold;
        margin-right: .267rem;
    }
    .msg p{
            text-indent: .267rem;
    }
}
</style>
